<template>
  <wide-panel
    :breadcrumbs="breadcrumbs"
    :title="title"
  >
    <v-card>
      <v-toolbar
        dense
        card
      >
        <v-spacer />
        <v-text-field
          v-model="searchTier1"
          append-icon="search"
          label="Search"
          single-line
          hide-details
        />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <v-tooltip
          v-if="items1.length"
          bottom
        >
          <template #activator="{ on }">
            <v-btn
              absolute
              fab
              dark
              small
              right
              color="green"
              v-on="on"
              @click="exportTier1"
            >
              <v-icon dark>
                mdi-microsoft-excel
              </v-icon>
            </v-btn>
          </template>
          <span>
            Export Report
          </span>
        </v-tooltip>
        <!--v-tooltip bottom>
          <v-btn
            slot="activator"
            icon
            @click="showSearch = !showSearch"
          >
            <v-icon>{{ showSearch ? 'cancel' : 'mdi-magnify' }}</v-icon>
          </v-btn>
          <span>{{ showSearch ? 'Hide search' : 'Show search' }}</span>
        </v-tooltip-->
      </v-toolbar>
      <v-progress-linear
        ref="progress"
        :indeterminate="!tierCount || progressSearch"
        :color="progressSearch ? 'orange' : 'primary'"
        height="2"
        class="my-0"
      />
      <v-slide-y-transition v-if="showSearch">
        <v-card>
          <v-sheet class="pa-3 primary">
            <v-text-field
              v-model="search"
              append-icon="mdi-account-search"
              label="Enter Dealer ID/MSISDN"
              flat
              dark
              solo-inverted
              hide-details
              clearable
              clear-icon="mdi-close-circle-outline"
              @click:append="findSubscriber()"
            />
          </v-sheet>
          <v-card-text
            v-if="showSearchResult"
            class="py-0"
          >
            <v-alert
              v-if="searchError"
              :value="true"
              type="error"
            >
              {{ searchError }}
            </v-alert>
            <v-timeline
              v-else
              align-top
              dense
            >
              <v-timeline-item
                fill-dot
                class="white--text"
                color="orange"
                icon="mdi-account-tie"
              >
                <v-card
                  color="orange"
                  dark
                >
                  <v-card-title class="title">
                    You
                  </v-card-title>
                </v-card>
              </v-timeline-item>
              <v-timeline-item
                v-for="(item, i) in uplines"
                :key="i"
                :color="item.dealer ? 'teal' : 'pink'"
                class="white--text"
                fill-dot
              >
                <template #icon>
                  <span class="text--primary">{{ item.tier }}</span>
                </template>
                <v-card
                  :color="item.dealer ? 'teal' : 'pink'"
                  dark
                >
                  <v-card-title
                    v-if="item.dealer"
                    class="title"
                  >
                    {{ item.dealer }}
                  </v-card-title>
                  <v-card-text class="grey text--primary">
                    <v-layout
                      row
                      wrap
                      pt-3
                    >
                      <v-flex xs3>
                        Msisdn:
                      </v-flex>
                      <v-flex xs9>
                        <strong>{{ item.msisdn }}</strong>
                        <div class="caption">
                          {{ item.plan }}
                        </div>
                      </v-flex>
                      <v-flex xs3>
                        Name:
                      </v-flex>
                      <v-flex xs9>
                        <strong>{{ item.name }}</strong>
                        <template v-if="item.dealer">
                          <div class="caption">
                            {{ item.rank }} ({{ item.dealership }})
                          </div>
                        </template>
                      </v-flex>
                      <v-flex xs3>
                        Status:
                      </v-flex>
                      <v-flex xs9>
                        <strong>{{ item.status }}</strong>
                        <div class="caption">
                          {{ item.activation }}
                        </div>
                      </v-flex>
                      <template v-if="item.dealer">
                        <v-flex xs3>
                          Direct Dealers:
                        </v-flex>
                        <v-flex xs9>
                          <strong>{{ item.dealerCount }}</strong>
                        </v-flex>
                        <v-flex xs3>
                          Active Activations:
                        </v-flex>
                        <v-flex xs9>
                          <strong>{{ item.subsCount }}</strong>
                        </v-flex>
                      </template>
                    </v-layout>
                  </v-card-text>
                </v-card>
              </v-timeline-item>
            </v-timeline>
          </v-card-text>
        </v-card>
      </v-slide-y-transition>
      <v-expansion-panel
        v-model="tier"
        popout
        focusable
      >
        <v-expansion-panel-content
          v-for="(item,i) in tierCount"
          :key="i"
          lazy
          ripple
        >
          <template #header>
            <div>Tier {{ item.tier }}: <span class="info--text">{{ item.count }} subscribers</span></div>
          </template>
          <v-sheet v-if="unlimitedInfo">
            <v-tooltip bottom>
              <template #activator="{ on }">
                <v-btn
                  absolute
                  fab
                  dark
                  small
                  right
                  top
                  :disabled="!enableExportTier"
                  :loading="!enableExportTier"
                  color="green"
                  v-on="on"
                  @click="exportTier"
                >
                  <v-icon dark>
                    mdi-microsoft-excel
                  </v-icon>
                </v-btn>
              </template>
              <span>
                Export Report
              </span>
            </v-tooltip>
          </v-sheet>
          <v-data-table
            :headers="headers"
            :search="searchTier1"
            :items="items"
            :loading="loading"
            :rows-per-page-items="[10, 25, 50, 100]"
            class="px-1 pb-1"
          >
            <template
              slot="items"
              slot-scope="props"
            >
              <tr>
                <td class="text-xs-left">
                  {{ props.item.dealer ? props.item.dealer : '-' }}
                </td>
                <td class="text-xs-left">
                  <!--v-btn
                    color="primary"
                    flat
                    @click="showDetails(props.item.id, props.item.msisdn)"
                  -->
                  {{ props.item.msisdn }}
                  <!--/v-btn-->
                </td>
                <td class="text-xs-left">
                  {{ props.item.plan }}
                </td>
                <td class="text-xs-left">
                  {{ props.item.activation }}
                </td>
                <td
                  v-if="props.item.channel"
                  class="text-xs-left"
                >
                  {{ props.item.channel }}
                </td>
                <td
                  v-else
                  class="text-xs-left"
                >
                  -
                </td>
                <td class="text-xs-left">
                  {{ props.item.name }}
                </td>
                <td class="text-xs-left">
                  <span
                    v-if="props.item.status === 'Active'"
                    class="success--text"
                  >{{ props.item.status }}</span>
                  <span
                    v-else
                    class="error--text"
                  >{{ props.item.status }}</span>
                </td>
                <td
                  v-if="props.item.uguMsisdn"
                  class="text-xs-left"
                >
                  {{ props.item.uguMsisdn }}
                </td>
                <td
                  v-else
                  class="text-xs-left"
                >
                  -
                </td>
                <td
                  v-if="props.item.status === 'Active' && Number(props.item.recharge) > 0"
                  class="success--text text-xs-right"
                >
                  {{ props.item.recharge }}
                </td>
                <td
                  v-else-if="props.item.status === 'Active' && Number(props.item.recharge) === 0"
                  class="warning--text text-xs-right"
                >
                  {{ props.item.recharge }}
                </td>
                <td
                  v-else
                  class="error--text text-xs-right"
                >
                  -
                </td>
              </tr>
            </template>
            <template slot="no-data">
              <v-alert
                :value="true"
                :type="error ? 'error' : 'info'"
              >
                {{ noDataText }}
              </v-alert>
            </template>
            <template slot="no-result">
              <v-alert
                :value="true"
                color="error"
                icon="warning"
              >
                {{ noResultText }}
              </v-alert>
            </template>
          </v-data-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-card>
  </wide-panel>
</template>

<script>
import { createGetParams } from '@/rest'
import security from '@/security'
import zipcelx from 'zipcelx'

const reportTitle = 'Tiers'

export default {
  components: {
    WidePanel: () => import(/* webpackChunkName: "widePanel" */ '@/components/WidePanel'),
  },
  data () {
    return {
      breadcrumbs: [
        {
          text: 'Subscriber Group', disabled: false, to: '/subscriber_group',
        },
        {
          text: reportTitle, disabled: true,
        },
      ],
      unlimitedInfo: false,
      error: null,
      enableExportTier: true,
      exportTierHeader:
        [
          {
            value: 'Dealer',
            type: 'string',
          },
          {
            value: 'Msisdn',
            type: 'string',
          },
          {
            value: 'Plan',
            type: 'string',
          },
          {
            value: 'Activation',
            type: 'string',
          },
          {
            value: 'Channel',
            type: 'string',
          },
          {
            value: 'Name',
            type: 'string',
          },
          {
            value: 'Status',
            type: 'string',
          },
          {
            value: 'Ugu',
            type: 'string',
          },
          {
            value: 'Recharge',
            type: 'string',
          },
        ],
      exportedReport: [],
      headers: [
        {
          align: 'left',
          sortable: true,
          text: 'Dealer',
          value: 'dealer',
        },
        {
          align: 'left',
          sortable: true,
          text: 'Msisdn',
          value: 'msisdn',
        },
        {
          align: 'left',
          sortable: true,
          text: 'Plan',
          value: 'plan',
        },
        {
          align: 'left',
          sortable: true,
          text: 'Activation',
          value: 'activation',
        },
        {
          align: 'left',
          sortable: true,
          text: 'Channel',
          value: 'channel',
        },
        {
          align: 'left',
          sortable: true,
          text: 'Name',
          value: 'name',
        },
        {
          align: 'left',
          sortable: true,
          text: 'Status',
          value: 'status',
        },
        {
          align: 'left',
          sortable: true,
          text: 'Ugu',
          value: 'ugu',
        },
        {
          align: 'right',
          sortable: true,
          text: 'Topup',
          value: 'recharge',
        },
      ],
      items1: [],
      items: [],
      loading: false,
      noResultText: 'No data that fits that criteria.',
      open: [1, 2],
      pagination: [],
      progressSearch: false,
      searchTier1: '',
      search: '',
      searchError: null,
      showSearch: false,
      showSearchResult: false,
      tierCount: null,
      tier: -1,
      title: reportTitle,
      totalItems: [],
      uplines: [],
    }
  },
  computed: {
    filter () {
      return this.caseSensitive
        ? (item, search, textKey) => item[textKey].indexOf(search) > -1
        : undefined
    },
    noDataText: function () {
      return this.error ? this.error : "There's nothing to display."
    },
  },
  watch: {
    search: function (val) {
      this.showSearchResult = false
      this.progressSearch = false
      this.searchError = null
    },
    /*
    pagination: {
      handler (val) {
        this.tier > -1 && this.getSubscribersByTier(this.tier).then(data => {
          this.items[this.tier].splice(0)
          this.items[this.tier].push.apply(this.items[this.tier], data.items)
          this.totalItems.splice(this.tier, 1, data.totalItems)
        })
      },
      deep: true,
    },
    */
    tier: function (val) {
      this.getSubscribersByTier(val, true)
    },
  },
  mounted: function () {
    this.getSubscriberTiers()
    this.getSubscribersByTier1(0, true)
  },
  methods: {
    exportTier: function () {
      this.enableExportTier = false
      this.tier > -1 && this.getSubscribersByTier(this.tier, true).then(data => {
        this.exportedReport = data.items
      })
    },
    exportTier1: function (val) {
      const config = {
        filename: security.me.dealerId() + '-Tier-' + 1,
        sheet: {
          data: [],
        },
      }

      config.sheet.data.push(this.exportTierHeader)
      this.items1.forEach(item => {
        const subscriber = [
          {
            value: item.dealer ? item.dealer : '-',
            type: 'string',
          },
          {
            value: item.msisdn ? item.msisdn : '-',
            type: 'string',
          },
          {
            value: item.plan ? item.plan : '-',
            type: 'string',
          },
          {
            value: item.activation ? item.activation : '-',
            type: 'string',
          },
          {
            value: item.channel ? item.channel : '-',
            type: 'string',
          },
          {
            value: item.name ? item.name : '-',
            type: 'string',
          },
          {
            value: item.status ? item.status : '-',
            type: 'string',
          },
          {
            value: item.uguMsisdn ? item.uguMsisdn : '-',
            type: 'string',
          },
          {
            value: item.recharge,
            type: 'number',
          },
        ]

        config.sheet.data.push(subscriber)
      })

      zipcelx(config)
      this.enableExportTier = true
    },
    showDetails: function (id, msisdn) {
      this.showSearch = true
      this.search = msisdn
      this.$nextTick(() => {
        this.findSubscriber(id)
        this.$vuetify.goTo(this.$refs.progress, {
          duration: 1000,
          offset: 0,
          easing: 'easeInOutCubic',
        })
      })
    },
    async findSubscriber (byId) {
      const param = byId ? { id: byId } : { search: this.search }
      this.progressSearch = true
      this.searchError = null
      this.uplines = []
      try {
        const response = await this.$rest.get('traceSubscriberInGroup.php', createGetParams(param))
        this.uplines = response.data.sort((a, b) => {
          return a.tier - b.tier
        })
      } catch (error) {
        if (error.response) {
          if (error.response.status === 404) {
            this.searchError = 'Search item not found under the subscriber group!'
          } else if (error.response.data) {
            this.searchError = error.response.data
          } else {
            this.searchError = error.message
          }
        } else if (error.request) {
          this.searchError = error.request
        } else {
          this.searchError = error.message
        }
      }
      this.progressSearch = false
      this.showSearchResult = true
    },
    async getSubscriberTiers () {
      try {
        const response = await this.$rest.get('getSubscriberTiers.php', createGetParams({}))
        this.tierCount = response.data
        for (var i = 0; i < this.tierCount.length; i++) {
          this.loading.push(false)
          // this.items.push([])
          this.pagination.push({})
          this.totalItems.push(0)
        }
      } catch (error) {}
    },
    async getSubscribersByTier1 (tier, exportReport = false) {
      let params = createGetParams({
        tier: tier + 1,
        export: true,
      })
      // this.loading.splice(tier, 1, true)
      if (!exportReport) {
        const { page, rowsPerPage, sortBy, descending } = this.pagination[tier]
        params = createGetParams({
          page,
          rowsPerPage,
          tier: tier + 1,
          sortBy,
          descending,
        })
      }
      try {
        this.loading = true
        const response = await this.$rest.get('getSubscribersByTier.php', params)
        this.error = null
        this.items1 = response.data.items
        this.totalItems = response.data.totalItems
        this.loading = false
        // this.loading.push(false)
      } catch (error) {
        this.enableExportTier = true
        this.error = error.message
      }
    },
    async getSubscribersByTier (tier, exportReport = false) {
      let params = createGetParams({
        tier: tier + 1,
        export: true,
      })
      // this.loading.splice(tier, 1, true)
      if (!exportReport) {
        const { page, rowsPerPage, sortBy, descending } = this.pagination[tier]
        params = createGetParams({
          page,
          rowsPerPage,
          tier: tier + 1,
          sortBy,
          descending,
        })
      }
      try {
        this.loading = true
        const response = await this.$rest.get('getSubscribersByTier.php', params)
        this.error = null
        this.items = response.data.items
        this.totalItems = response.data.totalItems
        this.loading = false
        // this.loading.push(false)
      } catch (error) {
        this.enableExportTier = true
        this.error = error.message
      }
    },
  },
}
</script>

<style scoped>
.theme--light.application .text--primary {
  color: rgba(255,255,255,.87) !important;
}
</style>
